import React from "react"
import clsx from "clsx"
import { CSSTransition, TransitionGroup } from "react-transition-group"

import { Box } from "../../_common"
import { StaticListProps } from "./types"
import classes from "./List.module.scss"

interface ListProps
  extends Pick<
    StaticListProps,
    "listClassName" | "listItemClassName" | "content" | "renderItem"
  > {
  timeout?: number
}

export const List: React.FC<ListProps> = ({
  listClassName,
  listItemClassName,
  content,
  renderItem,
}) => {
  return (
    <Box
      component="ul"
      className={clsx(classes.list, listClassName)}
      data-testid="experts-list"
    >
      {content.map((expert, index) => (
        <Box
          component="li"
          key={expert.listing_number}
          className={clsx(classes.listItem, listItemClassName)}
        >
          {renderItem(expert, index)}
        </Box>
      ))}
    </Box>
  )
}

export const ListWithTransition: React.FC<ListProps> = ({
  listClassName,
  listItemClassName,
  content,
  renderItem,
  timeout = 500, // has no effect for now
}) => {
  return (
    <Box
      component="ul"
      className={clsx(classes.list, listClassName)}
      data-testid="experts-list-with-transition"
    >
      {/* @ts-ignore */}

      <TransitionGroup component={null} appear={false} enter={false}>
        {content.map((expert, index) => (
          //@ts-ignore
          <CSSTransition
            key={expert.listing_number}
            timeout={timeout}
            classNames={{
              exit: classes.exit,
              exitActive: classes.exitActive,
            }}
          >
            <Box
              component="li"
              className={clsx(classes.listItem, listItemClassName)}
            >
              {renderItem(expert, index)}
            </Box>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </Box>
  )
}
