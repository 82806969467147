"use client"

import React, { useEffect } from "react"
import { useTranslations } from "next-intl"
import {
  ReadonlyURLSearchParams,
  usePathname,
  useSearchParams,
} from "next/navigation"

import { Box, Paper } from "../../_common"
import { LoadMoreButton } from "../../_common/Buttons"
import ExpertItemPlaceholder from "./ExpertItemPlaceholder"
import FavoriteExpertItemPlaceholder from "./FavoriteExpertItemPlaceholder"
import { List, ListWithTransition } from "./List"
import { StaticListProps } from "./types"
import useExpertsListingTracking from "../../../hooks/tracking/useExpertsListingTracking"
import { ClientTrackingEventName } from "../../../types"
import classes from "./StaticList.module.scss"

const makeNextUrl = (
  pageNumber: number,
  pathname: string,
  searchParams: ReadonlyURLSearchParams
): string => {
  const currentParams = new URLSearchParams(searchParams)
  currentParams.set("page", pageNumber.toString())

  return `${pathname}?${currentParams}`
}

/**
 * TODO: leave default placeholderState component.
 * Move isFavoriteExperts to upper level.
 */
const renderPlaceholderState = (
  placeholderSize: number,
  isFavoriteExperts: boolean
) =>
  Array(placeholderSize)
    .fill(0)
    .map((_, i) =>
      isFavoriteExperts ? (
        // eslint-disable-next-line react/no-array-index-key
        <FavoriteExpertItemPlaceholder key={i} />
      ) : (
        // eslint-disable-next-line react/no-array-index-key
        <ExpertItemPlaceholder key={i} />
      )
    )

/**
 * TODO: Need to refactor static list component to use
 * emptyState, customErrorSection, placeholderSize props in more
 * evident way, maybe update with renderProp approach,
 * to remove this logic in upper level and leave representative
 * component behavior clear.
 */
const StaticList: React.FC<StaticListProps> = ({
  content,
  isLast = false,
  withPagination,
  onLoadMoreClick = () => {},
  nextPageNumber = 0,
  isLoading = false,
  isLoadingMore = false,
  listItemClassName,
  listClassName,
  isFavoriteExperts = false,
  customErrorSection,
  placeholderSize = 3,
  renderItem,
  emptyState,
  withTransition = false,
  getClientTrackingProperties,
}) => {
  const translate = useTranslations()
  const { trackEvent: trackExpertsListingEvent } = useExpertsListingTracking()

  const contentIsEmpty = content.length === 0

  const showLoadMore: boolean = !contentIsEmpty && withPagination && !isLast
  const pathname = usePathname()
  const searchParams = useSearchParams()
  useEffect(() => {
    if (contentIsEmpty) return

    const customClientTrackingProperties =
      getClientTrackingProperties && getClientTrackingProperties(content)

    if (customClientTrackingProperties !== false) {
      trackExpertsListingEvent({
        content,
        eventName: ClientTrackingEventName.PRODUCT_LIST_VIEW,
        customClientTrackingProperties,
      })
    }
  }, [contentIsEmpty])

  if (contentIsEmpty) {
    if (isLoading) {
      return <>{renderPlaceholderState(placeholderSize, isFavoriteExperts)}</>
    }

    if (emptyState) {
      return <>{emptyState}</>
    }

    if (customErrorSection) {
      return <>{customErrorSection}</>
    }

    return (
      <Paper
        testId="empty-result"
        withError
        title="Deine Suche ergab keine Ergebnisse."
      >
        Vesuche es erneut
      </Paper>
    )
  }

  return (
    <>
      {withTransition ? (
        <ListWithTransition
          content={content}
          renderItem={renderItem}
          listClassName={listClassName}
          listItemClassName={listItemClassName}
        />
      ) : (
        <List
          content={content}
          renderItem={renderItem}
          listClassName={listClassName}
          listItemClassName={listItemClassName}
        />
      )}

      {showLoadMore && (
        <Box textAlign="center" className={classes.showMore}>
          <LoadMoreButton
            isLoading={isLoadingMore}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault()
              onLoadMoreClick(nextPageNumber)
            }}
            to={makeNextUrl(nextPageNumber, pathname, searchParams)}
            label={translate("loadMoreExperts")}
            variant="outlined"
            fullWidth
            buttonClassName={classes.loadMoreButton}
          />
        </Box>
      )}
    </>
  )
}

export default StaticList
